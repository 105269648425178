import { useStaticQuery, graphql } from 'gatsby';

const infoGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsInfoHotelstop (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              pdf {
                url
              }
              title
              publishedAt
              link {
                href
                blank
              }
              eyecatch {
                url
                height
                width
              }
              infoHotelstopId
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsInfoHotelstop.edges
  return newsdata
}

export default infoGet